<template>
    <div class="frame-content">
        <div class="frame-content-wrap">
            <div class="filter">
                <div class="inline mr-8">
                    <el-input v-model="filters.name" placeholder="分包单位名称" size="small"></el-input>
                </div>
                <div class="inline mr-8">
                    <el-select v-model="filters.status" clearable placeholder="状态" size="small">
                        <el-option label="已进场" value="1"></el-option>
                        <el-option label="已退场" value="2"></el-option>
                    </el-select>
                </div>
                <div class="inline mr-8">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="loadTable">查询</el-button>
                </div>
            </div>
            <div class="datatable-wrap mt-10">
                <div class="topbar">
                    <div class="inline mr-8">
                        <el-button type="primary" size="small" @click="handleAdd" :disabled="$store.state.org.type != 2">分包单位登记</el-button>
                    </div>
                    <!-- <div class="inline mr-8">
                        <el-button type="danger" size="small" @click="handleDeleteAll">删除</el-button>
                    </div> -->
                    <!-- <div class="inline mr-8">
                        <el-button type="primary" size="small" @click="handleAdd">导出Excel</el-button>
                    </div> -->
                </div>
                <div class="datatable">
                    <el-table
                        ref="currentTable"
                        :data="tableData"
                        v-loading="tableLoading"
                        element-loading-background="rgba(255, 255, 255, 0.3)"
                        border
                        style="width: 100%">
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center">
                        </el-table-column>
                        <el-table-column
                            label="序号"
                            align="center"
                            width="80">
                            <template slot-scope="scope">
                                <span>{{ (curPageNum - 1) * pageSize + scope.$index + 1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="分包单位名称"
                            width="200">
                        </el-table-column>
                        <el-table-column
                            prop="faren"
                            label="法人">
                        </el-table-column>
                        <el-table-column
                            prop="fuzeren"
                            label="负责人">
                        </el-table-column>
                        <el-table-column
                            prop="tel"
                            label="联系电话">
                        </el-table-column>
                        <el-table-column
                            prop="status"
                            label="状态">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.status === 0" type="warning" disable-transitions>未进场</el-tag>
                                <el-tag v-if="scope.row.status === 1" type="success" disable-transitions>已进场</el-tag>
                                <el-tag v-if="scope.row.status === 2" type="info" disable-transitions>已退场</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="inDate"
                            label="进场日期">
                        </el-table-column>
                        <!-- <el-table-column
                            prop="relPersonCount"
                            label="实际入住人数（人）">
                        </el-table-column>
                        <el-table-column
                            prop="arrearRent"
                            label="欠费租金（元）">
                            <template slot-scope="scope">
                                <span v-if="scope.row.arrearRent != '--'" class="red">{{ scope.row.arrearRent }}</span>
                                <template v-else>{{ scope.row.arrearRent }}</template>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="arrearDeposit"
                            label="欠费押金（元）">
                            <template slot-scope="scope">
                                <span v-if="scope.row.arrearDeposit != '--'" class="red">{{ scope.row.arrearDeposit }}</span>
                                <template v-else>{{ scope.row.arrearDeposit }}</template>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="payableRent"
                            label="应缴租金（元）">
                        </el-table-column>
                        <el-table-column
                            prop="paidRent"
                            label="实缴租金（元）">
                            <template slot-scope="scope">
                                <span v-if="scope.row.payableRent > scope.row.paidRent" class="red">{{ scope.row.paidRent }}</span>
                                <template v-else>{{ scope.row.paidRent }}</template>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="status"
                            label="状态">
                            <template slot-scope="scope">
                                <el-tag
                                    :type="scope.row.status === 1 ? 'primary' : 'danger'"
                                    disable-transitions>{{scope.row.status === 1 ? '在场' : '退场'}}</el-tag>
                            </template>
                        </el-table-column> -->
                        <el-table-column
                            prop="action"
                            label="操作"
                            align="center"
                            width="240">
                            <template slot-scope="scope">
                                <!-- <el-button
                                    size="mini"
                                    type="primary"
                                    @click="handleApplyRoom(scope.$index, scope.row)">入住</el-button> -->
<!--                                <el-button-->
<!--                                    size="mini"-->
<!--                                    type="warning"-->
<!--                                    @click="handleCheckOut(scope.$index, scope.row)">申请退房</el-button>-->
                                <el-button
                                    size="mini"
                                    type="primary"
                                    @click="handleDetail(scope.$index, scope.row)">详情</el-button>
                                <el-button
                                    size="mini"
                                    type="primary"
                                    @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                                <el-button
                                    size="mini"
                                    type="danger"
                                    @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :background="true"
                        :current-page="pageNum"
                        :page-sizes="[50, 100, 200, 300, 400, 500]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalNum">
                    </el-pagination>
                </div>
            </div>

            <el-dialog
                title="分包单位详情"
                v-if="detailDialog.create"
                :visible.sync="detailDialog.visible"
                :close-on-click-modal="false"
                @closed="handleDetailClosed"
                class="custom-dialog mini-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <SubcontractorDetail :params="detailDialog.params"></SubcontractorDetail>
            </el-dialog>

            <el-dialog
                title="分包单位登记"
                v-if="addDialog.create"
                :visible.sync="addDialog.visible"
                :close-on-click-modal="false"
                @closed="handleAddClosed"
                class="custom-dialog mini-dialog">
                <SubcontractorAdd @done="handleAddDone"></SubcontractorAdd>
            </el-dialog>

            <el-dialog
                title="编辑分包单位"
                v-if="editDialog.create"
                :visible.sync="editDialog.visible"
                :close-on-click-modal="false"
                @closed="handleEditClosed"
                class="custom-dialog mini-dialog">
                <SubcontractorEdit :params="editDialog.params" @done="handleEditDone"></SubcontractorEdit>
            </el-dialog>

            <el-dialog
                title="申请房间"
                v-if="applyRoomDialog.create"
                :visible.sync="applyRoomDialog.visible"
                :close-on-click-modal="false"
                @closed="handleApplyRoomClosed"
                class="custom-dialog big-dialog">
                <ApplyRoom
                    ref="applyRoom"
                    :params="applyRoomDialog.params"
                    @select-room="handleApplyRoomSelectRoom"
                    @done="handleApplyRoomDone"
                ></ApplyRoom>
            </el-dialog>

            <el-dialog
                title="选择房间"
                v-if="selectRoomDialog.create"
                :visible.sync="selectRoomDialog.visible"
                :close-on-click-modal="false"
                @closed="handleSelectRoomClosed"
                class="custom-dialog big-dialog">
                <SelectRoom
                    ref="selectRoom"
                    :params="selectRoomDialog.params"
                    @done="handleSelectRoomDone"
                ></SelectRoom>
                <div slot="footer" class="custom-dialog-footer">
                    <span class="footer-inner">
                        <el-button @click="selectRoomDialog = { create: true, visible: false, params: {} }" size="small">取 消</el-button>
                        <el-button type="primary" @click="handleSelectRoomComfirm" size="small">确 定</el-button>
                    </span>
                </div>
            </el-dialog>

            <el-dialog
                title="申请退房"
                v-if="checkOutDialog.create"
                :visible.sync="checkOutDialog.visible"
                :close-on-click-modal="false"
                @closed="handleCheckOutClosed"
                class="custom-dialog big-dialog">
                <CheckOut
                    ref="applyRoom"
                    :params="checkOutDialog.params"
                    @done="handleCheckOutDone"
                ></CheckOut>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import common from '@/utils/common'
import http from '@/utils/http'
import SubcontractorDetail from './SubcontractorDetail'
import SubcontractorAdd from './SubcontractorAdd'
import SubcontractorEdit from './SubcontractorEdit'
import ApplyRoom from './ApplyRoom'
import SelectRoom from './SelectRoom'
import CheckOut from './CheckOut'

export default {
    name: 'HouseHoldSubcontractor',
    components: {
        SubcontractorDetail,
        SubcontractorAdd,
        SubcontractorEdit,
        ApplyRoom,
        SelectRoom,
        CheckOut,
    },
    data() {
        return {
            filters: {
                name: '',
                status: '',
            },

            tableData: [],

            totalNum: 0,
            pageNum: 1,
            pageSize: 50,

            curPageNum: 1,

            tableLoading: false,

            detailDialog: {
                create: false,
                visible: false,
                params: {},
            },

            addDialog: {
                create: false,
                visible: false,
            },

            editDialog: {
                create: false,
                visible: false,
                params: {},
            },

            applyRoomDialog: {
                create: false,
                visible: false,
                params: {},
            },

            selectRoomDialog: {
                create: false,
                visible: false,
                params: {},
            },

            checkOutDialog: {
                create: false,
                visible: false,
                params: {},
            },
        };
    },
    created () {
        this.loadTable();
    },
    methods: {
        ...common,
        // 加载表格数据
        loadTable() {
            let params = {...this.filters};

            params.pageNum = this.pageNum;
            params.pageSize = this.pageSize;

            this.tableLoading = true;
            http.get('v1/supplier/page', {
                params: params
            }).then(req => {
                this.tableLoading = false;
                if (req.data.code == http.SUCCESS) {
                    this.tableData = req.data.data.records;
                    this.totalNum = req.data.data.total;
                    this.curPageNum = this.pageNum;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.loadTable();
        },
        handleCurrentChange(pageNum) {
            this.pageNum = pageNum;
            this.loadTable();
        },

        // 详情弹窗口
        handleDetail(index, row) {
            this.detailDialog = { create: true, params: row };
            this.$nextTick(() => {
                this.detailDialog = { ...this.detailDialog, visible: true };
            });
        },
        handleDetailClosed() {
            this.detailDialog = { create: false, visible: false, params: {} };
        },

        // 添加弹窗口
        handleAdd() {
            this.addDialog = { create: true, visible: false };
            this.$nextTick(() => {
                this.addDialog = { create: true, visible: true };
            });
        },
        handleAddDone() {
            this.addDialog = { visible: false };
            this.loadTable();
        },
        handleAddClosed() {
            this.addDialog = { create: false, visible: false };
        },

        // 编辑弹窗口
        handleEdit(index, row) {
            this.editDialog = { create: true, visible: false, params: row };
            this.$nextTick(() => {
                this.editDialog = {  create: true, visible: true, params: row };
            });
        },
        handleEditDone() {
            this.editDialog = { create: true, visible: false, params: {} };
            this.loadTable();
        },
        handleEditClosed() {
            this.editDialog = { create: false, visible: false, params: {} };
        },

        // 申请房间
        handleApplyRoom(index, row) {
            this.applyRoomDialog = { create: true, visible: false, params: row };
            this.$nextTick(() => {
                this.applyRoomDialog = { create: true, visible: true, params: row };
            });
        },

        handleApplyRoomDone() {
            this.applyRoomDialog = { create: true, visible: false, params: {} };
            this.loadTable();
        },
        handleApplyRoomClosed() {
            this.applyRoomDialog = { create: false, visible: false, params: {} };
        },
        handleApplyRoomSelectRoom(data) {
            this.handleSelectRoom(data);
        },

        // 选择房间
        handleSelectRoom(data) {
            this.selectRoomDialog = { create: true, visible: false, params: data };
            this.$nextTick(() => {
                this.selectRoomDialog = { create: true, visible: true, params: data };
            });
        },

        handleSelectRoomDone() {
            this.selectRoomDialog = { create: true, visible: false, params: [] };
        },
        handleSelectRoomClosed() {
            this.selectRoomDialog = { create: false, visible: false, params: [] };
        },
        handleSelectRoomComfirm() {
           let storeyGroup = this.$refs.selectRoom.getStoreyGroup();
           this.$refs.applyRoom.updateStoreyGroup(storeyGroup);
           this.handleSelectRoomDone();
        },

        // 申请退房
        handleCheckOut(index, row) {
            this.checkOutDialog = { create: true, visible: false, params: row };
            this.$nextTick(() => {
                this.checkOutDialog = { create: true, visible: true, params: row };
            });
        },

        handleCheckOutDone() {
            this.checkOutDialog = { create: true, visible: false, params: {} };
            this.loadTable();
        },
        handleCheckOutClosed() {
            this.checkOutDialog = { create: false, visible: false, params: {} };
        },

        handleDisable(index, row, status) {
            http.put(http.MOCK_Subcontractor_API_URL + 'demo/disable', { id: row.id, status: status }).then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.loadTable();
                    this.showSuccess('禁用成功！');
                } else {
                    this.showError(req.data.msg);
                }
            });
        },
        handleDelete(index, row) {
            this.confirm('确认删除该分包单位？', function () {
                http.post('v1/supplier/delete', { id: row.id }).then(req => {
                    if (req.data.code == http.SUCCESS) {
                        this.loadTable();
                        this.showSuccess('删除成功！');
                    } else {
                        this.showError(req.data.msg);
                    }
                });
            });
        },
        handleDeleteAll() {
            if (this.$refs.currentTable.selection.length == 0) {
                this.showError('请选择要删除的分包单位！');
            } else {
                this.confirm('确认删除所选分包单位？', function () {
                    let idarr = [];
                    for (let i = 0; i < this.$refs.currentTable.selection.length; i++) {
                        idarr.push(this.$refs.currentTable.selection[i].id);
                    }
                    http.post('v1/supplier/delete', { idList: idarr }).then(req => {
                        if (req.data.code == http.SUCCESS) {
                            this.loadTable();
                            this.showSuccess('删除成功！');
                        } else {
                            this.showError(req.data.msg);
                        }
                    });
                });
            }
        },
    }
}
</script>

<style scoped>
</style>
