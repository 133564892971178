<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" :show-message="true" class="form-col2">
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="单位名称" prop="name">
                        <el-input type="text" v-model="form.name" size="small" maxlength="50" @blur="fieldTrim(form, 'name')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="法人姓名" prop="faren">
                        <el-input type="text" v-model="form.faren" size="small" maxlength="50" @blur="fieldTrim(form, 'faren')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="负责人姓名" prop="fuzeren">
                        <el-input type="text" v-model="form.fuzeren" size="small" maxlength="50" @blur="fieldTrim(form, 'fuzeren')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="联系电话" prop="tel">
                        <el-input type="text" v-model="form.tel" size="small" maxlength="50" @blur="fieldTrim(form, 'tel')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="进场状态" prop="status">
                        <el-select v-model="form.status" placeholder="进场状态" size="small" style="width:100%;" @change="handleStatusChange">
                            <el-option label="未进场" :value="0"></el-option>
                            <el-option label="已进场" :value="1"></el-option>
                            <el-option label="已退场" :value="2"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" v-if="form.status > 0">
                <el-col :span="24">
                    <el-form-item label="进场日期" prop="inDate">
                        <el-date-picker
                            v-model="form.inDate"
                            align="right"
                            type="date"
                            placeholder="选择日期"
                            :picker-options="pickerOptions"
                            value-format="yyyy-MM-dd"
                            size="small">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="resetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"

export default {
    name: 'HouseHoldSubcontractorAdd',
    data(){
        return {
            pickerOptions: {},
            form: {
                projectId: '121212',
                name: '',
                faren: '',
                fuzeren: '',
                tel: '',
                status: '',
                inDate: '',
            },
            rules: {
                name: [{required: true, message: '单位名称不能为空', trigger: 'blur'}],
                faren: [{required: false, message: '法人姓名不能为空', trigger: 'blur'}],
                fuzeren: [{required: true, message: '负责人姓名不能为空', trigger: 'blur'}],
                tel: [{required: true, message: '联系电话不能为空', trigger: 'blur'}],
                status: [{required: true, message: '进场状态', trigger: 'blur'}],
                inDate: [{required: false, message: '进场日期不能为空', trigger: 'blur'}],
            },
            submitting: false
        }
    },
    created() {
        
    },
    methods: {
        ...common,
        ...verify,
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    http.post('v1/supplier/add', this.form).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('添加成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.submitting = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
        handleStatusChange(value) {
            this.rules.inDate[0].required = value > 0;
            this.$refs.form.clearValidate("inDate");
        },
    }
};
</script>

<style scoped>
</style>