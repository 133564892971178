<template>
    <div class="dialog-inner form-col2" style="padding-top:0;">
        <div class="form-group-title"><h2>分包单位</h2></div>
        <el-row :gutter="20">
            <el-col :span="12">
                <div class="data-item">
                    <div class="label">分包单位名称：</div>
                    <div class="text">{{data.name}}</div>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="data-item">
                    <div class="label">状态：</div>
                    <div class="text">
                        <span v-if="data.status === ''" type="warning" disable-transitions size="small">-</span>
                        <el-tag v-if="data.status === 0" type="warning" disable-transitions size="small">未进场</el-tag>
                        <el-tag v-if="data.status === 1" type="success" disable-transitions size="small">已进场</el-tag>
                        <el-tag v-if="data.status === 2" type="info" disable-transitions size="small">已退场</el-tag>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12">
                <div class="data-item">
                    <div class="label">法人姓名：</div>
                    <div class="text">{{data.faren}}</div>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="data-item">
                    <div class="label">负责人姓名：</div>
                    <div class="text">{{data.fuzeren}}</div>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12">
                <div class="data-item">
                    <div class="label">联系电话：</div>
                    <div class="text">{{data.tel}}</div>
                </div>
            </el-col>
        </el-row>
        <div class="form-group-title"><h2>已申请房间</h2></div>
        <div class="select-room-box select-room-box-1">
            <template v-if="storeyList.length > 0">
                <div 
                    v-for="storey in storeyList" 
                    :key="storey.storey.id" 
                    class="item clearfix">
                    <div class="st"
                >{{ storey.building.name }}{{ storey.storey.name }}</div>
                    <ul>
                        <li 
                            v-for="room in storey.roomList" 
                            :key="room.id" 
                        >{{ room.name }}</li>
                    </ul>
                </div>
            </template>
            <div v-else class="empty">暂无数据</div>
        </div>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"

export default {
    name: 'SubcontractorDetail',
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            storeyList: [],
            data: {
                name: '',
                faren: '',
                fuzeren: '',
                tel: '',
                status: '',
            },
            submitting: false
        }
    },
    mounted() {
        this.data = this.params;
        this.loadData();
    },
    methods: {
        ...common,
        loadData() {
            let params = {};
            params.supplierId = this.params.id;
            let loading = this.load();
            http.get("v1/build/roomList", { params: params }).then(req => {
                loading.close();
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data;
                    
                    let map = {};
                    data.forEach(x => {
                        let item = map[x.floorId];
                        if (!item) {
                            item = {
                                building: {
                                    id: x.buildId,
                                    name: x.buildName,
                                },
                                storey: {
                                    id: x.floorId,
                                    name: x.floorName,
                                },
                                roomList: [],
                            };

                            map[x.floorId] = item;
                        }
                        item.roomList.push({id: x.roomId, name: x.roomName, ...x});
                    });

                    let arr = [];
                    for (let key in map) {
                        let item = map[key];
                        arr.push(item);
                    }

                    this.storeyList = arr;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                loading.close();
                this.httpCatch(err);
            });
        },
    }
};
</script>

<style scoped>
</style>